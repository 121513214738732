var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',{attrs:{"icon":_vm.$route.meta.icon,"title":_vm.$route.meta.title},scopedSlots:_vm._u([{key:"tools",fn:function(){return [(_vm.isAnima())?_c('s-icon-report-download-optimized',{attrs:{"show-message":"","report":"21"}}):_c('emc-excel-export-icon',{attrs:{"report-id":"21"}})]},proxy:true}])},[_c('emc-loader-progress',{attrs:{"show":_vm.loadingProgress}}),_c('sys-activity-item-filter',{attrs:{"show":_vm.dialogFilter},on:{"update:show":function($event){_vm.dialogFilter=$event},"filter":function($event){_vm.payloadFilter = $event; _vm.getData(); _vm.dialogFilter = false}}}),_c('emc-table-items',{attrs:{"headers":_vm.headers,"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemDelete":_vm.itemDelete,"itemEdit":_vm.model,"loading":_vm.loading,"selected-items":_vm.selected,"show-select":"","find":"","showAppend":"","showActions":false},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemDelete":function($event){_vm.itemDelete=$event},"update:item-delete":function($event){_vm.itemDelete=$event},"update:itemEdit":function($event){_vm.model=$event},"update:item-edit":function($event){_vm.model=$event},"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.payloadFilter ? 'primary' : 'grey',"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.dialogFilter = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"btn":""}},[_vm._v(_vm._s(_vm.payloadFilter ? 'mdi-filter-menu-outline' : 'mdi-filter-outline'))])],1)]}}])},[_c('span',[_vm._v(" Demais Filtros ")])])]},proxy:true},{key:"item.campus",fn:function(ref){
var item = ref.item;
return [(item.campus)?_c('span',[_vm._v(" "+_vm._s(item.campus.name)+" ")]):(item.departament)?_c('span',[_vm._v(_vm._s(item.departament.name))]):_vm._e()]}},{key:"item.teachers",fn:function(ref){
var item = ref.item;
return _vm._l((item.teachers),function(teacher,i){return _c('span',{key:i},[(teacher.teacher)?_c('span',[(i > 0)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(teacher.teacher.name)+" "),_c('small',[_c('br'),_vm._v("Valor: "+_vm._s(parseFloat(teacher.value)))]),_c('small',[_c('br'),_vm._v(_vm._s(_vm._f("date")(teacher.start))+" - "+_vm._s(_vm._f("date")(teacher.end)))])]):_vm._e()])})}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.value))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.periodicityType.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.valueType.name)+" ")]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.activity.name)+" "),(item.activity.category)?_c('small',[_c('br'),_vm._v(_vm._s(item.activity.category.name))]):_vm._e(),(item.activity.category && item.activity.category.departament)?_c('small',[_c('br'),_vm._v(_vm._s(item.activity.category.departament.name))]):_vm._e(),(item.is_work_load)?_c('span',[_c('br'),_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-check-bold")]),_c('small',[_vm._v(" Compõe CH")])],1):_vm._e()]}},{key:"item.register",fn:function(ref){
var item = ref.item;
return [_vm._v(" Usuário: "),(item.userCreator)?_c('span',[_vm._v(" "+_vm._s(item.userCreator.name)+" "),_c('br'),_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.created_at)))])]):_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTime")(item.created_at))+" ")]),(item.userApproval)?_c('span',[_c('br'),_vm._v(" Aprovador: "+_vm._s(item.userApproval.name)+" "),_c('br'),_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.date_approval)))])]):_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(item.date_approval)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",staticStyle:{"width":"130px"},attrs:{"color":_vm.tags[item.status.id - 1].color,"outlined":""}},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.tags[item.status.id - 1].name))]),(item.note_approval)?_c('emc-info-help',{attrs:{"title":"Observação do aprovador","icon":"mdi-chat-processing-outline"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" "+_vm._s(item.note_approval)+" "),(item.userApproval)?_c('span',[_c('br'),_c('br'),_vm._v("Aprovador: "),_c('strong',[_vm._v(_vm._s(item.userApproval.name))])]):_vm._e(),(item.date_approval)?_c('small',[_c('br'),_vm._v(_vm._s(_vm._f("dateTime")(item.date_approval)))]):_vm._e()]},proxy:true}],null,true)}):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('emc-table-icon-edit',{attrs:{"tool-tip":"Ver Detalhes da Atividade","icon":"mdi-file-eye-outline"},on:{"click":function($event){return _vm.showDetails(item)}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.tags[item.status.id - 1].color},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showFormApproval(0, item)}}},'v-btn',attrs,false),on),[(item.exported)?_c('v-icon',[_vm._v(_vm._s(_vm.tags[5].icon))]):_c('v-icon',[_vm._v(_vm._s(_vm.tags[item.activity_item_status_id - 1].icon))])],1)]}}],null,true)},[_c('span',[(item.exported)?_c('span',[_vm._v("Remover")]):([1,4].includes(item.activity_item_status_id))?_c('span',[_vm._v("Aprovar")]):([2,3].includes(item.activity_item_status_id))?_c('span',[_vm._v("Reprovar")]):(item.activity_item_status_id == 4)?_c('span',[_vm._v("Aprovar")]):_vm._e()])]),(item.exported && (item.activity_item_status_id != 6 && item.activity_item_status_id != 7))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showFormApproval(7, item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Finalizar")])]):_vm._e()]}},{key:"footer.prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue","disabled":!_vm.selected || _vm.selected.length == 0},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showFormApproval(2)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-thumb-up-outline")])],1)]}}])},[_c('span',[_vm._v(" Aprovar Selecionados ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error","disabled":!_vm.selected || _vm.selected.length == 0},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showFormApproval(4)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-thumb-down-outline")])],1)]}}])},[_c('span',[_vm._v(" Reprovar Selecionados ")])]),(_vm.selected.length)?_c('span',{staticClass:"mr-1"},[_vm._v("Items Selecionados: "+_vm._s(_vm.selected.length))]):_vm._e()]},proxy:true}])}),_c('sys-activity-item-details',{attrs:{"show":_vm.dialogDetails,"model":_vm.selectedItem},on:{"update:show":function($event){_vm.dialogDetails=$event}}}),_c('sys-register-activity-item-approval',{attrs:{"status-id":_vm.statusId,"selected":_vm.selectedItems,"show":_vm.showForm},on:{"onCompleted":function($event){return _vm.getData()},"update:show":function($event){_vm.showForm=$event}}}),_c('emc-alert-modal',{attrs:{"text":_vm.textDelete,"disabled":_vm.deleting,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])}),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }