import { mapActions, mapState } from 'vuex'

export default{
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    selected: [],
    statusId: 0,
    selectedItems: [],
    itemDelete: {},
    model: {},
    payloadFilter: null,
    dialogFilter: false,
    showImport: false,
    show: false,
    showForm: false,
    dialogDetails: false,
    selectedItem: null,
    searchUserCreator: null,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    headers: [
        { text: 'Campus/Departamento', value: 'campus', sortable: false},
        { text: 'Professor', value: 'teachers', sortable: false},
        { text: 'Atividade', value: 'activity', sortable: false},
        { text: 'Tipo', value: 'type', sortable: false},
        { text: 'Cadastro', value: 'register', sortable: false},
        { text: 'Status', value: 'status', align: 'center', hidden: true, sortable: false},
        { text: 'Ações', value: 'actions'},

    ],
    filtersTags: [0, 1, 2, 3, 4],
    icons: ['mdi-check', 'mdi-close-circle-outline'],
    tags: [
        { id: 0, name: 'Pendente',      color: 'grey',      icon: 'mdi-thumb-up-outline' },
        { id: 1, name: 'Autorizado N1', color: 'primary',   icon: 'mdi-thumb-up-outline' },
        { id: 2, name: 'Autorizado N2', color: 'primary',   icon: 'mdi-thumb-up-outline' },
        { id: 3, name: 'Reprovado',     color: 'error',     icon: 'mdi-thumb-down-outline'   },
        { id: 4, name: 'Realizado',     color: 'purple',    icon: 'mdi-thumb-up-outline'  },
        { id: 5, name: 'Removido',      color: 'error',     icon: 'mdi-account-remove'   },
        { id: 6, name: 'Finalizado',    color: 'error',     icon: 'mdi-calendar-remove'   },
        
      ],
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item " + item_new.activity.name + "?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },
    computed: {
        ...mapState('auth', ['acesso', 'user']),
        
        itemsFiltered(){
            
            var items = this.collections.filter(i => this.filtersTags.includes(i.activity_item_status_id - 1));
            if(this.searchUserCreator){
                items = items.filter(i => (i.userCreator && i.userCreator.name.toString().toLocaleUpperCase().indexOf(this.searchUserCreator.toString().toLocaleUpperCase()) !== -1));
            }

            return items;
        }

    },
    methods: {
      ...mapActions('activityItem', ['ActionFindActivityItems', 'ActionDeleteActivityItem']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.showForm = false;
        this.selected = [];
        

        this.params.with = 'logs.user,campus,departament,courses,activity.category.departament,activity.paymentEvent,valueType,periodicityType,status,userApproval,userCreator,teachers.teacher,costCenter';
        this.params.filterByDepartament = true;

        delete this.params.find_columns;
        delete this.params.filter_relations;

        if(this.payloadFilter){
            this.params.find_columns        = this.payloadFilter.find_columns;
            this.params.filter_relations    = this.payloadFilter.filter_relations;
        }

        this.ActionFindActivityItems(this.params)
            .then((res) => {
                this.collections    = res.data;
                this.meta           = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    showDetails(item){
        this.selectedItem = item;
        this.dialogDetails = true;
    },

    showFormApproval(status, item = null){

        if(item){
            this.selectedItems = [item];
            if(item.exported){
                this.statusId = status == 7 ? 7 : 6;
            }else{
                this.statusId = item.activity_item_status_id == 1 || item.activity_item_status_id == 4 ? 2 : 4;
            }
            
        }else{
            this.selectedItems = this.selected;
            this.statusId = status;
        }

        this.showForm = true;

    },


  },

}
